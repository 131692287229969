import axios from "axios";


const GiftApi = axios.create({
    baseURL: 'https://api.boxes.digital',
    responseType: "json",
    timeout : 1000000
});

export const sendGiftData = async (voucher ,address) => {


    return await GiftApi.get(`/purchase/${voucher}/${address}`)
        .then((res) => {
            return res;
        }).catch((e) => {
            if (!e.response) {
                return false;
            }
            return e.response;
        })
};