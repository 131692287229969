import React, {useState} from "react";
import classes from "./Mobile.module.css";


import "./Mobile.css"
import {images} from "../../assets/images/images";
import {sendGiftData} from "../../api/giftApi";
import Icon from "../../components/Icon/Icon";


const Mobile = (props) => {

    const [gift, setGift] = useState({address: "", voucher: ""});
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");
    const [giftData, setgiftData] = useState("");


    const GiftsData = async () => {
        setLoading(true)
        const GiftDataReq = await sendGiftData(gift.voucher, gift.address);
        if (GiftDataReq && GiftDataReq.status === 200) {
            setError(false)
            setgiftData(GiftDataReq.data)
            setLoading(false)
        } else {
            setError(GiftDataReq.data)
        }
        setLoading(false)
    }

    const submit = async (e) => {
        e.preventDefault();

        if(gift.address === "" || gift.voucher === ""){
            return false
        }

        if (giftData === ""){
            GiftsData(gift.voucher, gift.address);
        } else {
            setgiftData("")
            setGift({address: "", voucher: ""})
        }


    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(giftData.transactionHash)
    };
    return (
        <>
            <div className={`container position-relative ${classes.wrapper}`}
                 style={{backgroundImage: `url("${images.GiftBoxesMobile}")`}}/>
            <div className={`container position-absolute ${classes.container} flex jc-center ai-center`}>
                {loading ? <div className={`container height-100 column jc-center ai-center`}>
                        <span className={`mb-05`}>Please wait for the transfer to complete</span>
                        <span className={`mt-05`}>This time depends on network speed :)</span>
                </div> :

                    <form className={`${classes.content}`} onSubmit={(e) => submit(e)}>
                        <div className={`container ${classes.header} flex jc-center ai-center`}>
                            <span>{giftData !== "" ? giftData.msg : "Boxes NFT"}</span>
                        </div>


                        <div className={`container ${classes.body} ${classes.inputGroup} ${giftData !== "" ? classes.result : "" } column  ${giftData !== "" ? "jc-around" : "jc-center" }  ai-center`}>
                            {giftData !== "" ?
                                <>
                                    <span className={`font-size-md`}>Box #<span style={{color: 'orange'}}>{giftData.boxNumber}</span> is yours</span>
                                    <div className={`container column jc-center ai-center`}>
                                        <div className={`container row jc-around ai-center`}>
                                            <img className={`width-30 flex jc-center ai-center`} src={images.boxes} alt="boxes"/>
                                            <span className={`width-30 flex jc-center ai-center`}>BOXES</span>
                                            <Icon iconName="icon-open-external-link flex font-size-sm-plus" iconClass={`cursor-pointer hover width-30 flex jc-center ai-center`} title={`Box #${giftData.boxNumber} in BOXES`} onClick={()=>window.open(`${giftData.boxInfo}`)}/>
                                        </div>
                                        <div className={`container row jc-around ai-center`}>
                                            <img className={`width-30 flex jc-center ai-center`} src={images.polygonscan} alt="polygonscan"/>
                                            <span className={`width-30 flex jc-center ai-center`}>polygonscan</span>
                                            <Icon iconName="icon-open-external-link flex font-size-sm-plus" iconClass={`cursor-pointer hover width-30 flex jc-center ai-center`} title={`Box #${giftData.boxNumber} in polygonscan`} onClick={()=>window.open(`${giftData.txScan}`)}/>
                                        </div>
                                        <div className={`container row jc-around ai-center`}>
                                            <img className={`width-30 flex jc-center ai-center`} src={images.opensea} alt="opensea"/>
                                            <span className={`width-30 flex jc-center ai-center`}>opensea</span>
                                            <Icon iconName="icon-open-external-link flex font-size-sm-plus" iconClass={`cursor-pointer hover width-30 flex jc-center ai-center`} title={`Box #${giftData.boxNumber} in opensea`} onClick={()=>window.open(`${giftData.inventory}`)}/>
                                        </div>
                                    </div>
                                    <div className={`container row jc-center ai-center mt-2`} onClick={() => copyToClipboard()} title={`Click to copy Transaction Hash`}>
                                        <Icon iconName="icon-copy-link flex font-size-sm-plus" iconClass={`mr-1 cursor-pointer hover`} onClick={() => copyToClipboard()} title={`Click to copy Transaction Hash`}/>
                                        <span onClick={() => copyToClipboard()} title={`Click to copy Transaction Hash`}>Click to copy Transaction Hash</span>
                                        {/*<span className={`font-size-sm-plus`} >{giftData.transactionHash}</span>*/}
                                    </div>

                                </>
                                :
                                <>
                                    <div className={`row ${classes.link} font-size-sm-plus`}>
                                        <span className={`pr-1 cursor-pointer ${classes.hover}`} onClick={()=>window.open('https://boxes.digital/')}>Boxes </span><span> | </span><span className={`pl-1 cursor-pointer ${classes.hover}`} onClick={()=>window.open('https://help.boxes.digital/')}> Help</span>
                                    </div>
                                    <div className={`${classes.label} mb-4`}>
                                        <span>Enter your polygon address and voucher to receive the gift</span>
                                    </div>
                                    <div className={`${classes.input}`}>
                                        <input
                                            type="text"
                                            placeholder="Polygon Address"
                                            value={gift.address}
                                            onChange={(e) => setGift({...gift, address: e.target.value})}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Voucher"
                                            value={gift.voucher}
                                            onChange={(e) => setGift({...gift, voucher: e.target.value})}
                                        />
                                        <div className={`${classes.alert} column jc-start mt-05`}>
                                            {error !== "" ? <span>{error.msg}</span> : ""}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={`container ${classes.footer} ${giftData !== "" ? classes.result : "" } flex jc-center ai-center`}>
                            <button
                                type="submit"
                                className={`flex jc-center ai-center cursor-pointer`}
                            >
                                {giftData !== "" ? "Back" : "Submit"}
                            </button>
                        </div>

                    </form>
                }
            </div>
        </>

    );
};

export default Mobile;
