import React, {useState} from "react";
import {BrowserView, isBrowser, isIPad13, isMobile, isTablet, MobileView} from "react-device-detect";
import Browser from "./Browser/Browser";
import Mobile from "./Mobile/Mobile";


const Main = (props) => {



    let mainContent = <div/>

    if (isMobile) {
        mainContent = <Mobile/>
    }
    if (isTablet) {
        mainContent = <Mobile/>
    }
    if (isBrowser) {
        mainContent = <Browser/>

    }



    return (
        /*basename={"home"}*/
        /*"homepage":"https://boxes.digital"*/
        <>
            {mainContent}
        </>
    );
};

export default Main;