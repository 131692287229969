import GiftBoxesBrowser from "./src/Gift.BoxesBrowser.png";
import GiftBoxesMobile from "./src/Gift.Boxes.Mobile.png";
import BoxesBrowserMotion from "./src/Gift.BoxesBrowserMotion.png";
import boxes from "./src/boxes.svg";
import polygonscan from "./src/polygonscan.svg";
import opensea from "./src/opensea.svg";



export const images = {
    GiftBoxesBrowser: GiftBoxesBrowser,
    GiftBoxesMobile: GiftBoxesMobile,
    BoxesBrowserMotion: BoxesBrowserMotion,
    boxes: boxes,
    polygonscan: polygonscan,
    opensea: opensea,

};
