import React from "react";

const Icon = (props) => {

    return (
        <span className={props.iconClass} onClick={props.onClick} title={props.title}>
            <i className={props.iconName}/>
        </span>
    )
};

export default Icon;